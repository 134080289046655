.img-container {
  background-color: #0f0f0f;
  display: block !important;
  padding: 1em;
  overflow: hidden;
}

.img-container h1 {
  color: #fff !important;
  font-family: 'Messapia-Bold';
}

.img-container p {
  padding: 0.5em;
  color: #fff;
}

.img-container button.btn-quote {
  padding: 1em;
  width: auto;
  font-size: 1em;
  font-weight: bold;
  background-color: #e02e16;
  color: #fff;
  border-radius: 4px;
  box-shadow: 2px 4px #1c1c1c;
  margin: 0 2em;
  border: 0;
  font-family: 'Geometria';
}

.img-container img.logo-head {
  width: 21em;
  height: 5em;
  margin-top: 6em;
}

.section {
  width: 100vw;
  display: block;
  background-color: #0f0f0f;
}

.section .lema-container {
  width: 100vw;
  padding: 2em;
  display: block;
  margin: auto;
  color: #ddd;
}

.section .lema-container h1 {
  font-family: 'Geometria-Bold';
  font-weight: bold;
  font-size: 28px;
  text-transform: uppercase;
}

.section .lema-container h2 {
  font-family: 'Messapia-Bold';
  font-size: 24px;
  width: 3em;
  padding: 5px 0;
  overflow-x: visible;
  color: #c3922e;
  border-bottom: 4px solid #c3922e;
  border-top: 4px solid #c3922e;
  letter-spacing: 5px; 
}

.section .lema-container h3 {
  font-family: 'Geometria-Bold';
  font-size: 22px;
  text-transform: uppercase;
}

.section .lema-container p {
  font-family: 'Geometria-Thin';
  font-size: 22px;
}

.section .semblanza {
  display: block;
  width: 100vw;
  background-color: #fff;
  color: #1c1c1c;
  padding: 1em;
}

.section .semblanza p {
  font-family: 'Geometria-Light';
  font-size: 18px; 
}

.section .semblanza .afiliations {
  display: block;
}

.section .semblanza .afiliations a {
  margin: auto;
  padding: 1em;
}

.section .semblanza .afiliations a img {
  width: 20em;
  height: 20em;
  object-fit: contain;
}

@media only screen and (min-width: 768px) {
 
  .img-container {
    height: 43vh !important;
  } 

  .img-container p {
    width: 40em;
  }

  .section {
    display: flex;
    margin: 0;
  }

  .section .lema-container {
    width: 30vw;
    margin: 0 auto;
  }

  .section .semblanza {
    width: 70vw;
    margin: auto;
  }

  .section .semblanza .afiliations {
    display: flex;
  }
  
  .img-container img.logo-head {
    width: 35em;
    height: 11em;
    margin-top: 4em;
    object-fit: contain;
  }
}
