.section-service {
  width: 100vw;
  min-height: 786px;
  display: flex;
  background-color: #0f0f0f;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.service {
  width: 95vw;
  max-width: 680px;
  padding: 2em;
  border-radius: 8px;
  display: block;
  margin: 2em auto;
  color: #ccc;
}

.service hr {
  color: #c3922e;
  background-color: #c3922e;
  border: 0;
  height: 2px;
}
