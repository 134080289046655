.message {
  margin: 8px auto;
  padding: 4px;
  text-align: center;
}

.success {
  border: 1px solid #c3942f;
  color: #c3942f;
}

.sending {
  border: 1px solid #fff;
  color: #fff;
}

.error {
  border: 1px solid RED;
  color: RED;
}

.form-container {
  margin: 0 auto;
}
