--contact-vars {
  
}

.contact-container *:focus{
  outline: none;
}

.contact-container {
  margin: 0;
  padding: 0;
  display: flex;
  background-color: #0f0f0f;
  color: #fff;
  height: 80vh;
  width: 100vw;
  flex-direction: column;
}

.contact-container .text {
  margin: 0 auto;
  display: block;
  text-align: center;
  height: 20vh;
}

.contact-container .text p a {
  color: #fff;
}

.contact-container form {
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 13px;
  column-gap: 5px;
}

input {
  width: 50vw;
  border: 2px solid #5f5f5f;
  background-color: #2f2f2f;
  color: #fff;
  border-radius: 4px;
  height: 2em;
  transition: ease-in-out, border .35s ease-in-out;
}

textarea {
  width: 50vw;
  border: 2px solid #5f5f5f;
  background-color:#2f2f2f;
  color: #fff;
  border-radius: 4px;
  transition: ease-in-out, border .35 ease-in-out;
  resize: none; 
}

button {
  border: 2px solid #5f5f5f;
  background-color:#2f2f2f;
  color: #fff;
  border-radius: 4px;
  transition: ease-in-out, border .35 ease-in-out; 
}

input[type="submit"] {
  grid-area: 5/2;
  margin: 0;
  width: 75px;
}

.contact-container form label span {
  color: RED;
  padding: 0 2px;
}

input:invalid {
  box-shadow: 0 0 3px red;
  border: 2px solid red;
}

button:hover {
  border: 2px solid #c3922e;
}

input:hover {
  border: 2px solid #c3922e;
}

textarea:hover {
  border: 2px solid #c3922e;
}

input:focus {
  border: 3px solid #c3922e;
}

textarea:focus {
  border: 3px solid #c3922e;
}

@media only screen and (min-width: 768px) {
  input {
    width: 30vw;
  }

  textarea {
    width: 30vw;
  }
}
