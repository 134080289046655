.social-media-icon {
  display: block;
  margin: 0;
  padding: 5px;
}

.social-media-icon img {
  width: 50px;
  height: 50px;
  margin: auto;
}
