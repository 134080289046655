:root {
  --menu-text-color: #fff;
  --menu-bg-color: #0f0f0f;
  --menu-bg-haighlight: #0f0f0f; 
  --menu-text-haighlight: #c3922e;
  --menu-width: 70vw;
  --menu-item-width: 13em;
  --submenu-bg-color: rgba(0,0,0, 0.7);
  --submenu-bg-hl-color: rgba(15, 15, 15, 0.7);
  --submenu-txt-color: #fff;
  --submenu-txt-hl-color: #c3922e;
}

.menu {
  width: var(--menu-width);
  height: 3em;
  display: flex;
  position: relative;
  margin: auto;
}

.menu .items {
  width: 100%;
  display: flex;
  background-color: var(--menu-bg-color);
}

.menu .items ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
}

.menu .items ul.sm-menu {
  display: none;
}

 .menu .items ul li {
  min-width: 9rem;
  width: var(--menu-item-width);
  display: flex;
}

.menu .items ul li button {
  padding: 0;
  margin: auto;
  background-color: var(--menu-bg-color);
  color: var(--menu-text-color);
  text-decoration: none;
  border: 0;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  font-family: 'Geometria';
}

.menu .items ul li button:hover {
  background-color: var(--menu-bg-haighlight);
  color: var(--menu-text-haighlight);
}

.menu .items ul li a {
  padding: 0;
  margin: auto;
  color: var(--menu-text-color);
  text-decoration: none;
}

.menu .items ul li:hover{
  background-color: var(--menu-bg-haighlight);
  color: var(--menu-text-haighlight);
}

.menu .items ul li.dropdown .dropdown-content {
  width: var(--menu-item-width);
  display: none;
  position: absolute;
  float: left;
  top: 3rem;
  margin: 0;
  background-color: var(--submenu-bg-color);
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: .75rem;
}

.dropdown-content .show:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  left: 6rem;
  border: .75rem solid transparent;
  border-top: 0;
  border-bottom-color: var(--submenu-bg-color);
  margin-left: 0.75rem;
  margin-top: -0.75rem;
}

.menu .items ul li.dropdown .dropdown-content a {
  text-decoration: none;
  min-width: 9rem;
  width: var(--menu-item-width);
  color: var(--submenu-txt-color);
  padding: 1em;
  display: inline-block;
}

.menu .items ul li.dropdown > button:after {
  content: '\25BE';
}

.menu .items ul li.dropdown .dropdown-content a:hover {
  background-color: var(--submenu-bg-hl-color);
  color: var(--submenu-txt-hl-color);
}

.menu .items ul li.dropdown .dropdown-content a:first-child:hover {
  border-radius: .75rem .75rem 0 0;
}

.menu .items ul li.dropdown .dropdown-content a:last-child:hover {
  border-radius: 0 0 .75rem .75rem;
}

.menu .items ul li.dropdown:hover .dropdown-content {
  flex-direction: column;
  display: block;
}



.menu .items ul li.dropdown .dropdown-content span button.collapsible {
  min-width: 10rem;
  width: var(--menu-item-width);
  background-color: var(--submenu-bg-color);
  color: var(--submenu-txt-color);
  padding: 1em;
  text-align: left;
}

.menu .items ul li.dropdown .dropdown-content span button.collapsible:hover {
  background-color: var(--submenu-bg-hl-color);
  color: var(--submenu-txt-hl-color);
}

.menu .items ul li.dropdown .dropdown-content span button.collapsible:after {
  content:"\25BE"; /* "\02795"; */
  color: var(--submenu-txt-color);
}

.menu .items ul li.dropdown .dropdown-content span button.collapsible:hover {
  content:"\25BE"; /* "\02795"; */
  color: var(--submenu-txt-hl-color);
}

.show {
  display: block !important;
}

.active:after {
  content: "\25B8" !important;
}

.menu .items ul li.dropdown .dropdown-content span .collapsible-content {
  overflow: hidden;
  display: none;
  transition: width 1s, height 1s;
}

.menu .items ul li.dropdown .dropdown-content span .collapsible-content a {
  padding-left: 1.5em;
  margin: .25rem;
}

.menu .items ul li.dropdown .dropdown-content span .collapsible-content a:hover {
  background-color: var(--submenu-bg-hl-color);
  color: var(--submenu-txt-hl-color);
}


@media only screen and (max-width: 768px) {
   .menu .items ul.normal-menu {
    display: none;
  }

   .menu .items ul.sm-menu {
    display: flex;
  }
}
