@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Geometria.ttf') format('truetype'),
       url('../assets/fonts/Geometria.woff') format('woff'),
       url('../assets/fonts/Geometria.woff2') format('woff2'),
       url('../assets/fonts/Geometria.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Messapia-Bold";
  src: url("../assets/fonts/Messapia-Bold.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Messapia-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../assets/fonts/Messapia-Bold.otf") format("opentype"), /* Open Type Font */
    url("../assets/fonts/Messapia-Bold.svg") format("svg"), /* Legacy iOS */
    url("../assets/fonts/Messapia-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../assets/fonts/Messapia-Bold.woff") format("woff"), /* Modern Browsers */
    url("../assets/fonts/Messapia-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Geometria-Light";
  src: url("../assets/fonts/Geometria-Light.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Geometria-Light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../assets/fonts/Geometria-Light.otf") format("opentype"), /* Open Type Font */
    url("../assets/fonts/Geometria-Light.svg") format("svg"), /* Legacy iOS */
    url("../assets/fonts/Geometria-Light.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../assets/fonts/Geometria-Light.woff") format("woff"), /* Modern Browsers */
    url("../assets/fonts/Geometria-Light.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Geometria-Thin";
  src: url("../assets/fonts/Geometria-Thin.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Geometria-Thin.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../assets/fonts/Geometria-Thin.otf") format("opentype"), /* Open Type Font */
    url("../assets/fonts/Geometria-Thin.svg") format("svg"), /* Legacy iOS */
    url("../assets/fonts/Geometria-Thin.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../assets/fonts/Geometria-Thin.woff") format("woff"), /* Modern Browsers */
    url("../assets/fonts/Geometria-Thin.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Geometria-Bold";
  src: url("../assets/fonts/Geometria-Bold.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Geometria-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../assets/fonts/Geometria-Bold.otf") format("opentype"), /* Open Type Font */
    url("../assets/fonts/Geometria-Bold.svg") format("svg"), /* Legacy iOS */
    url("../assets/fonts/Geometria-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../assets/fonts/Geometria-Bold.woff") format("woff"), /* Modern Browsers */
    url("../assets/fonts/Geometria-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;  
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Geometria';
}

header {
  width: calc(100vw);
  background-color: #0f0f0f;
  display: block;
  position: sticky;
  top: 0;
}

header a {
  margin: 0 auto;
}

body {
  overflow-x: hidden;
}

header a img {
  width: 18em;
  height: 5em;
  object-fit: contain;
  margin: 0 auto;
}

@supports (display: grid) {
  header {
    display: grid;
  }
}

@media only screen and (min-width: 768px) {

  header {
    display: flex;
  }

  header img {
    width: 20em;
    height: 5em;
    object-fit: contain;
  }

  .menu .items {
    justify-content: right;
  }
}
