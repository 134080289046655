.footer {
  display: block;
  width: 100vw;
  padding: 1em;
  background-color: #0f0f0f;
}

.footer .location {
  font-family: Geometria;
  font-size: 0.75em;
  margin: auto;
  width: 100vw;
  color: #fff;
  display: grid;
  grid-template-columns: 8rem 1fr; 
}

.footer .location a {
  text-decoration: none;
  color: #fff;
}

.footer .social-media {
  display: flex;
  width: 100vw;
  justify-content: center;
  margin: 1rem 0;
}

@media only screen and (min-width: 769px) {
  .footer {
    display: flex;
  }
}
